<template>
  <section class="container">
    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Choose The Pricing Plan</h4>
              <p class="text-muted para-desc mb-0 mx-auto">
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-12 mt-4 pt-2">
            <div
              class="
                card
                pricing-rates
                business-rate
                shadow
                bg-light
                rounded
                text-center
                border-0
              "
            >
              <!---->
              <div class="card-body py-5">
                <h6
                  class="
                    title
                    font-weight-bold
                    text-uppercase text-primary
                    mb-4
                  "
                >
                  Free
                </h6>
                <div class="d-flex justify-content-center mb-4">
                  <span class="h4 mb-0 mt-2">$</span
                  ><span class="price h1 mb-0">0</span
                  ><span class="h4 align-self-end mb-1">/mo</span>
                </div>
                <ul class="list-unstyled mb-0 pl-0">
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uil uil-check-circle align-middle"></i></span
                    >Full Access
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uil uil-check-circle align-middle"></i></span
                    >Enhanced Security
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uil uil-check-circle align-middle"></i></span
                    >Source Files
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uil uil-check-circle align-middle"></i></span
                    >1 Domain Free
                  </li>
                </ul>
                <a href="javascript:void(0)" class="btn btn-primary mt-4"
                  >Buy Now</a
                >
              </div>
            </div>
          </div>
          <div class="col-md-4 col-12 mt-4 pt-2">
            <div
              class="
                card
                pricing-rates
                business-rate
                shadow
                bg-light
                rounded
                text-center
                border-0
              "
            >
              <div class="ribbon ribbon-right ribbon-warning overflow-hidden">
                <span class="text-center d-block shadow small h6">Best</span>
              </div>
              <div class="card-body py-5">
                <h6
                  class="
                    title
                    font-weight-bold
                    text-uppercase text-primary
                    mb-4
                  "
                >
                  Starter
                </h6>
                <div class="d-flex justify-content-center mb-4">
                  <span class="h4 mb-0 mt-2">$</span
                  ><span class="price h1 mb-0">39</span
                  ><span class="h4 align-self-end mb-1">/mo</span>
                </div>
                <ul class="list-unstyled mb-0 pl-0">
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uil uil-check-circle align-middle"></i></span
                    >Full Access
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uil uil-check-circle align-middle"></i></span
                    >Source Files
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uil uil-check-circle align-middle"></i></span
                    >Free Appointments
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uil uil-check-circle align-middle"></i></span
                    >Enhanced Security
                  </li>
                </ul>
                <a href="javascript:void(0)" class="btn btn-primary mt-4"
                  >Get Started</a
                >
              </div>
            </div>
          </div>
          <div class="col-md-4 col-12 mt-4 pt-2">
            <div
              class="
                card
                pricing-rates
                business-rate
                shadow
                bg-light
                rounded
                text-center
                border-0
              "
            >
              <!---->
              <div class="card-body py-5">
                <h6
                  class="
                    title
                    font-weight-bold
                    text-uppercase text-primary
                    mb-4
                  "
                >
                  Professional
                </h6>
                <div class="d-flex justify-content-center mb-4">
                  <span class="h4 mb-0 mt-2">$</span
                  ><span class="price h1 mb-0">59</span
                  ><span class="h4 align-self-end mb-1">/mo</span>
                </div>
                <ul class="list-unstyled mb-0 pl-0">
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uil uil-check-circle align-middle"></i></span
                    >Full Access
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uil uil-check-circle align-middle"></i></span
                    >Enhanced Security
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uil uil-check-circle align-middle"></i></span
                    >Source Files
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"
                      ><i class="uil uil-check-circle align-middle"></i></span
                    >1 Domain Free
                  </li>
                </ul>
                <a href="javascript:void(0)" class="btn btn-primary mt-4"
                  >Try it Now</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
export default {
  props: {
    props: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
};
</script>

<style>
</style>